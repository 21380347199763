import React from 'react';
import Slider from 'react-slick';
import $ from "jquery";
import FullPage from './FullPage';
import * as utils from '../utils/utils';
import '../styles/project.scss';
import '../styles/vendor/slick.css'; 
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    useHistory
  } from "react-router-dom";
let colorMatch = [];
/**
 * Build project template
 * 
 * @param {object} project 
 * @returns 
 */
const Project = function (project) {
    const content = project.data.node.acfprojects;
    let isExclude = false;
    let category = '';
    let color = '';
    colorMatch.forEach((item) => {
        if (content.categorie === item.name) {
            category = content.categorie;
            color = item.color;
        }
    });

    if (project.excludeCategory.length > 0) {
        project.excludeCategory[0].forEach((isExcl) => {
            if (isExcl.length > 0) {
                if (utils.cleanString(category) === utils.cleanString(isExcl)) {
                    isExclude = true;
                }
            }
        });  
    } 

    const [activeSlider, setActiveSlider] = React.useState(false);
    const unBlockSlider = (data, index) => {
        setActiveSlider(!data);
        project.blockSlider(!data);
    }

    const addClassOnSlider = function (target) {
        $('.slick-slide').removeClass('active');
        $(target.target).closest('.slick-slide').addClass('active');
        $('body').addClass('active');
       
    }

    return (
        <Router basename="/portfolio/">
            <div className="container" style={{ "--color-transition":`${color}`}}>
              <NavLink exact activeClassName="current-project" to={{pathname : '/' + ((content.titre).replace(/ /g,"-")).replace(":-",""), state: { prevPath: '/' }}}>
                 {
                     <div onClick= {
                            (target) => {
                            project.blockSlider(!activeSlider);
                            addClassOnSlider(target);
                            }
                          }
                          className={isExclude ? "exclude project__wrapper" : "project__wrapper" } 
                          data-category={category} 
                          style={{ borderColor:`${color}`}}>
                            <img src={content.img.sourceUrl} alt={content.titre} />
                            <div className="project__wrapper_content">
                                <h2 className="reveal-text">
                                    {content.titre}
                                </h2>
                            </div>  
                        </div>
                    
                 }
                
             </NavLink>  
             <Switch>
                 {
                     <Route key={project.index} path={'/' + ((content.titre).replace(/ /g,"-")).replace(":-","")}>
                        <FullPage content={content} unBlockSlider={unBlockSlider} />
                     </Route>
                 }
             </Switch>
            </div>
        </Router>
        
        )
}

/**
 * Build array with matches between color and category
 * @param {object} project 
 * @param {object} categories 
 */
const findColor = function(project, categories) {
    const content = project.node.acfprojects;
    if (categories) {
        categories.edges.forEach((category) => {
        const projectCategoryName = utils.cleanString(content.categorie);
        const categoryName = utils.cleanString(category.node.acfcategorie.categorie);
        if (projectCategoryName.replace(/ /g,'') === categoryName.replace(/ /g,'')) {
            const match = {};
            match.color = category.node.acfcategorie.color;
            match.name = content.categorie;
            colorMatch.push(match);
        }
    });    
    }
    
}

/**
 * Load all components
 * @param {*} props 
 * @returns 
 */
const AllComponents = function (props) {
    let projects = props.projectData.projects;
    const categories = props.categoryData.categories;
    const excludeCategory = props.excludeCategory;
    const actionCta = props.actionCta;
    const [disableSlider, setDisableSlider] = React.useState(false);
    const loaderString = 'loading...';
    const blockSlider = (data) => {
         setDisableSlider(data);  
         props.setCurrentState(data)
        setTimeout(() => {
            if (!data) {
                // reset state of slick
                if($('.slick-active button')[0]) {
                    $('.slick-active button')[0].click();
                }
            }
        }, 1000);
    }

    const settings = {
        dots: true,
        infinite: false,
        initialSlide: 0,
        variableWidth: true,
        speed: 500,
        slidesToShow:3,
        slidesToScroll: 3,
        unslick: disableSlider,
        responsive: [
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              variableWidth: false,
            }
          },
          {
            breakpoint: 660,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              variableWidth: false,
            }
          }
        ]
      };
      
    const history = useHistory();
    React.useEffect(() => {
        $('.current-project').closest('.slick-slide').addClass('active');
        
        if (history.location.pathname === window.location.pathname 
            && window.location.pathname !== '/portfolio/'
            && window.location.pathname !== '/portfolio') {
            setDisableSlider(true);  
            props.setCurrentState(true)
         }

         const cursor = $('.cursor');
         const $actionCta = $(actionCta);
         $actionCta.off();
         $actionCta.on('mouseenter', () => {
          cursor.addClass('hover');
        });
    
        $actionCta.on('mouseleave', () => {
          cursor.removeClass('hover');
        });
      }, [history.location.pathname, actionCta, props]);

    if (projects) {
        projects = projects.edges;
        return (
            
                <div className="projects__wrapper" data-project-main>
                   <Slider {...settings}>
                    {
                        projects.map((project, index) => (
                            <Project 
                            key={index} 
                            data={project} 
                            excludeCategory={excludeCategory} 
                            index={index} 
                            color={findColor(project, categories)}
                            blockSlider={blockSlider}  />  
                        ))
                    }
                    </Slider>
                   
                </div>
          
            );
    }

    return (<div className="loader projects loading">
        {
            [...loaderString].map((letter, index) => {
                return (<span className={letter === ' ' ? 'space' : ''} key={index}>{letter}</span>)
            })
        }
       </div>)
}

export default AllComponents;
