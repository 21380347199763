import * as query from './utils/query';
import Projects from './component/Project';
import Categories from './component/Category';
import React, {useState} from 'react';
import $ from "jquery";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
} from "react-router-dom";



function App() {
  const categoriesData = query.GetCategories();
  const projectData = query.GetProjects();
  const aboutData = query.GetAbout();
  const actionCta = '.action, .project__wrapper, .category__wrapper, .exit, .contact';
  const $actionCta = $(actionCta);
  let aboutContent = '';
  if (aboutData.pages) {
   aboutContent = aboutData.pages.edges[0].node.content;
  }
  
  const [excludeCategory, setExcludeCategory] = useState([]);

  const updateExcludeCategory = (data) => {
    setExcludeCategory([data]);
  }

  const [hideFooter, setCurrentState] = useState(false);

  const updateCurrentState = (data) => {
    setCurrentState(data);
  }

  const mouseInit = function () {
    const cursor = $('.cursor');
    $(document).on('mousemove', (e) => {
      cursor.attr('style', `top: ${e.pageY - 10}px; left: ${e.pageX - 10}px` );
    });


    $actionCta.on('mouseenter', () => {
      cursor.addClass('hover');
    });

    $actionCta.on('mouseleave', (e) => {
      cursor.removeClass('hover');
    });

  }

  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
  if (!isTablet) {
    $('html, body').addClass('hideCursor');
    mouseInit();
  }

  return (
    <div className="App">
      <Router>
      <header>
        <h1 className="reveal-text">
          Clément VALLANCE 🖖
          <span>Front-End Developer</span>  
        </h1>
        <div>
          <NavLink activeClassName="active" exact className="action header" to="/about/"><i className="far fa-user-circle"></i></NavLink>
          <NavLink activeClassName="active"  exact className="action header" to="/portfolio/"><i className="fas fa-home"></i></NavLink>
        </div>
          
      </header>
      <Route path='/portfolio/'>
        <Projects projectData={projectData} categoryData={categoriesData} excludeCategory={excludeCategory} setCurrentState={updateCurrentState} actionCta={actionCta}></Projects>
        <Categories projectData={projectData} categoryData={categoriesData} updateCategory={updateExcludeCategory}></Categories>
      </Route>
      


      {
          <Route path='/about/'>
              
            {
              <div>
                <div className="about__wrapper" dangerouslySetInnerHTML={{__html: aboutContent}}></div>
              </div>
              
            }
          </Route>
      }
      {
        !hideFooter ? <footer> 💬 Do you have a question ? <a className="contact" href="mailto:hello@clementvallance.fr"><span>Get in touch !</span></a></footer> : null
      }
       </Router>
    </div>
  );
}

export default App;
