import $ from "jquery";

export function cleanString(string) {
    return ((string).toLowerCase()).normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

export function mouseInit() {
    const cursor = $('.cursor');
    $(document).on('mousemove', (e) => {
      cursor.attr('style', `top: ${e.pageY - 10}px; left: ${e.pageX - 10}px` );
    });

    $('.action, .project__wrapper, .category__wrapper').on('mouseenter', () => {
      cursor.addClass('hover');
    });

    $('.action, .project__wrapper, .category__wrapper').on('mouseleave', (e) => {
      cursor.removeClass('hover');
    });
  }