import { gql, useQuery } from '@apollo/client';

const GET_YEARS = gql`
query 
{
    years{
      edges {
        node {
          acfyear {
            annee
            description
            fieldGroupName
          }
        }
      }
    }
  }
`;

const GET_CATEGORIES = gql`
query 
{
  categories {
    edges {
      node {
        acfcategorie {
          categorie
          color
          fieldGroupName
        }
      }
    }
  }
}
`;

const GET_PROJECTS = gql`
query 
{
    projects {
      edges {
        node {
          acfprojects {
            categorie
            description
            fieldGroupName
            intro
            test
            titre
            year
            img {
              sourceUrl
            }
            link {
              target
              title
              url
            }
          }
        }
      }
    }
  }
`;

const GET_ABOUT = gql`
query 
{
  pages {
    edges {
      node {
        content
        template {
          acfaccueil {
            aboutDescription
            aboutTitre
            fieldGroupName
            aboutCv {
              target
              title
              url
            }
          }
        }
      }
    }
  }
  }
`;

export function GetYears() {
     const { loading, error, data } = useQuery(GET_YEARS);
     if (loading) return 'Loading...';
     if (error) return `Error! ${error.message}`;
     return data;
}

export function GetProjects() {
    const { loading, error, data } = useQuery(GET_PROJECTS);
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    return data;
}

export function GetCategories() {
  const { loading, error, data } = useQuery(GET_CATEGORIES);
  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  return data;
}

export function GetAbout() {
  const { loading, error, data } = useQuery(GET_ABOUT);
  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  return data;
}