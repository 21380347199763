import React from 'react';
import App from './App'
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HttpLink } from 'apollo-link-http';
import './styles/reset.scss';
import './styles/base.scss';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";
const client = new ApolloClient({
  link: new HttpLink({ uri: window.location.protocol + "//" + window.location.hostname + '/content/graphql' }),
 // link: new HttpLink({ uri: window.location.protocol + "//" + window.location.hostname + ':8888/content/graphql' }),
  cache: new InMemoryCache()
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root')
);